import React, { useEffect, useState } from 'react';
import {
  Container,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import loading from '../../../multimedia/loading.gif';
import { HiInformationCircle } from 'react-icons/hi2';
import { FaPlus, FaMinus } from 'react-icons/fa';

export default function AudioSegmentPage2({
  segmentData,
  projectId,
  segmentId,
  handleClose,
  audioSegments,
  setAudioSegments,
  speakers,
  updatedSpeakerId,
  setUpdatedSpeakerId,
  updatedSpeakerName,
  setUpdatedSpeakerName,
}) {
  const apiURL = process.env.REACT_APP_API_URL;
  const voices = JSON.parse(localStorage.getItem('voiceList'));
  const [isChecked, setIsChecked] = useState(false);
  const [stability, setStability] = useState(0.0);
  const [similarity, setSimilarity] = useState(0);
  const [text, setText] = useState('');
  const [style, setStyle] = useState(0);
  const [generatedData, setGeneratedData] = useState([]);
  const [generate, setGenerate] = useState(false);
  const [wait, setWait] = useState(false);
  const [count, setCount] = useState(0);
  const [speed, setSpeed] = useState(0);

  const maxSpeed = 2;
  const minSpeed = 0.5;
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);
    const milliseconds = Math.floor((time - Math.floor(time)) * 1000);

    const formattedMilliseconds = milliseconds
      .toString()
      .padStart(3, '0')
      .substring(0, 2);

    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}.${formattedMilliseconds}`;
  };

  useEffect(() => {
    if (segmentData) {
      setStability(segmentData.AudioSettings.variance);
      console.log(stability);
      // setSimilarity(segmentData.AudioSettings.similarity_boost);
      setStyle(segmentData.AudioSettings.style);
      setText(segmentData.Text);
      // if (segmentData.AudioSettings.use_speaker_boost === 1) {
      //   setIsChecked(true);
      // }
      setSpeed(segmentData.AudioSettings.speed);
    }
  }, []);

  useEffect(() => {
    var audio = document.getElementById('audio-Player');
    var timeDisplay = document.querySelector('#current-time');
    var totalTimeDisplay = document.querySelector('#total-time');
    if (audio && timeDisplay) {
      audio.addEventListener('timeupdate', function () {
        var currentTime = audio.currentTime;
        var duration = isNaN(audio.duration) ? 0 : audio.duration;
        var currentMinutes = Math.floor(currentTime / 60);
        var currentSeconds = Math.floor(currentTime - currentMinutes * 60);
        var currentMilliseconds = Math.floor(
          (currentTime - Math.floor(currentTime)) * 1000
        );
        var durationMinutes = Math.floor(duration / 60);
        var durationSeconds = Math.floor(duration - durationMinutes * 60);
        var durationMilliseconds = Math.floor(
          (duration - Math.floor(duration)) * 1000
        );

        var currentTimeString =
          currentMinutes +
          ':' +
          (currentSeconds < 10 ? '0' : '') +
          currentSeconds +
          '.' +
          (currentMilliseconds < 100 ? '0' : '') +
          (currentMilliseconds < 10 ? '0' : '') +
          currentMilliseconds;
        var durationString =
          durationMinutes +
          ':' +
          (durationSeconds < 10 ? '0' : '') +
          durationSeconds +
          '.' +
          (durationMilliseconds < 100 ? '0' : '') +
          (durationMilliseconds < 10 ? '0' : '') +
          durationMilliseconds;

        timeDisplay.innerHTML = currentTimeString;
        totalTimeDisplay.innerHTML = durationString;
      });
    }
  }, [segmentData, generatedData]);

  // generate api call here=================================================================================//

  function generateSingleAudioSegment() {
    setWait(true);
    console.log(stability);

    const req = {
      ProjectId: projectId,
      TargetLangId: segmentData.TargetLangId,
      AudioSegmentId: segmentId,
      AudioSettings: {
        variance: stability,
        style: style,
        speed: speed,
      },
      Text: text,
      SpeakerId: updatedSpeakerId,
      // VoiceId: segmentData.VoiceId,
      FileName: segmentData.FileName,
    };

    fetch(`${apiURL}/dub/generate_single_audio_segment`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(req),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.IsValid) {
          setGeneratedData((prevData) => {
            return [...prevData, result.AudioSegment];
          });
          setCount(count + 1);
          setGenerate(true);
          setWait(false);
          if (
            result.AudioSegment.AudioSegmentUrl !== '' &&
            result.AudioSegment.AudioSegmentUrl !== null &&
            result.AudioSegment.AudioSegmentUrl !== undefined
          ) {
            setTimeout(() => {
              playAudio();
            }, 100);
          }
        }
      })
      .catch((error) => {
        // console.error("An error occurred:", error);
        setWait(false);
      });
  }

  async function updateAudioSegment() {
    if (generate === true) {
      const req = {
        ProjectId: projectId,
        AudioSegmentList: [
          {
            AudioSettings: {
              variance: stability,
              style: style,
            },
            AudioSegmentUrl: generatedData[count - 1].AudioSegmentUrl,
            AudioSegmentId: segmentId,
            SpeakerId: updatedSpeakerId,
            FileName: generatedData[count - 1].FileName,
            Duration: generatedData[count - 1].Duration,
            Text: text,
          },
        ],
      };

      try {
        const response = await fetch(`${apiURL}/web/update_audio_segment`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(req),
        });

        const result = await response.json();

        if (result.IsValid) {
          const index = audioSegments.findIndex(
            (item) => item.Id === segmentId
          );
          console.log(index);

          const arr = [audioSegments[index]];

          const temp = arr.map((item) => ({
            ...item,
            Id: segmentId,
            AudioSettings: {
              variance: stability,
              style: style,
            },
            AudioSegmentUrl: generatedData[count - 1].AudioSegmentUrl,
            FileName: generatedData[count - 1].FileName,
            Duration: generatedData[count - 1].Duration,
            Text: text,
            SpeakerId: updatedSpeakerId,
            SpeakerName: updatedSpeakerName,
            Duration: generatedData[count - 1].Duration,
          }));
          console.log(temp);

          const updatedAudioSegments = [...audioSegments];
          // Created a shallow copy
          updatedAudioSegments[index] = {
            ...updatedAudioSegments[index],
            ...temp[0], // Replace with the properties from `temp`
          };

          console.log(updatedAudioSegments);

          setAudioSegments(updatedAudioSegments);
          handleClose();
          // fetchAudioSegments();
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    }
  }

  const handleSpeakerChange = (e) => {
    const selectedSpeakerId = e.target.value;
    // Find the corresponding speaker name from the list
    const selectedSpeaker = speakers.find(
      (speaker) => speaker.Id === selectedSpeakerId
    ).Name;

    console.log(selectedSpeakerId, selectedSpeaker);

    // Update both the ID and Name state
    setUpdatedSpeakerId(selectedSpeakerId);
    setUpdatedSpeakerName(selectedSpeaker);
  };

  // Fetch audio segments api call

  const fetchAudioSegments = async () => {
    const reqBody = {
      ProjectId: projectId,
      TargetLanguageId: segmentData.TargetLangId,
    };
    try {
      const response = await fetch(`${apiURL}/web/get_audio_segments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reqBody),
      });

      if (response.ok) {
        const newData = await response.json();

        if (newData.IsValid) {
          setAudioSegments([...(newData.ResultAudioSegments || [])]);
          handleClose();
        }
      } else {
        throw new Error('Failed to fetch project data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  function playAudio() {
    const audio = document.getElementById('audio-Player');
    audio.play();
  }

  const InformationCircleTooltip = ({ id, text }) => (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip style={{ fontSize: '12px' }} id={`tooltip-${id}`}>
          {text}
        </Tooltip>
      }
    >
      <span
        style={{
          fontSize: '16px',
          marginTop: '12px',
          marginLeft: '6px',
          color: 'gray',
        }}
      >
        <HiInformationCircle />
      </span>
    </OverlayTrigger>
  );

  function minSpeedChange() {
    let temp = speed;
    if (speed > minSpeed) {
      setSpeed(parseFloat((temp - 0.1).toFixed(1)));
    }
  }
  function maxSpeedChange() {
    let temp = speed;
    if (speed < maxSpeed) {
      setSpeed(parseFloat((temp + 0.1).toFixed(1)));
    }
  }
  return (
    <div className="segmentPage2">
      <Form>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            marginTop: -5,
          }}
        >
          <div style={{ width: '49.4%' }}>
            <Form.Label style={{ fontWeight: 500 }}>Text:</Form.Label>
            <div className="settings">
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label style={{ fontWeight: 500 }}>
                  Transcription:{' '}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  style={{ resize: 'none' }}
                  rows={3}
                  value={segmentData.TranscriptText}
                  readOnly
                />
                <Form.Label className="mt-3" style={{ fontWeight: 500 }}>
                  Translation:{' '}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  defaultValue={segmentData.Text}
                  onChange={(e) => setText(e.target.value)}
                />
              </Form.Group>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ width: '50%', paddingRight: '2%' }}>
                  <Form.Label className="mt-3" style={{ fontWeight: 500 }}>
                    Speaker:{' '}
                  </Form.Label>

                  <Form.Select
                    value={updatedSpeakerId}
                    onChange={(e) => handleSpeakerChange(e)}
                  >
                    {speakers &&
                      speakers.map((speaker) => (
                        <option key={speaker.Id} value={speaker.Id}>
                          {speaker.Name}
                        </option>
                      ))}
                  </Form.Select>
                </span>
                <span style={{ width: '50%', paddingLeft: '2%' }}>
                  <Form.Label className="mt-3" style={{ fontWeight: 500 }}>
                    Duration:{' '}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={`${segmentData.TranscriptDuration} s`}
                    readOnly
                  />
                </span>
              </div>
            </div>
          </div>
          <div style={{ width: '49.4%' }}>
            <Form.Label style={{ fontWeight: 500 }}>
              Settings{' '}
              <span
                style={{ color: 'gray', cursor: 'pointer' }}
                onClick={(e) =>
                  window.open(
                    'https://spectralstudios.ai/voice-settings/',
                    '_blank'
                  )
                }
              >
                <HiInformationCircle />
              </span>{' '}
              :
            </Form.Label>
            <div className="settings">
              <div>
                <span>Variance:</span>
                <Form.Range
                  className="mt-1"
                  min={0}
                  max={1}
                  step={0.05}
                  defaultValue={segmentData.AudioSettings.variance}
                  onChange={(e) => setStability(e.target.value)}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    fontSize: '12px',
                    marginTop: '-10px',
                  }}
                >
                  <Form.Label style={{ fontWeight: 500, color: 'gray' }}>
                    <span>More Stable</span>
                    <span style={{ fontSize: '16px' }}>
                      <InformationCircleTooltip
                        id="more-stable"
                        text="Increasing variance will make the voice more consistent between re-generations,
         but it can also make it sound a bit monotone. On longer text fragments we recommend lowering this value."
                      />
                    </span>
                  </Form.Label>
                  <Form.Label style={{ fontWeight: 500, color: 'gray' }}>
                    <span>More Variable</span>
                    <span style={{ fontSize: '16px' }}>
                      <InformationCircleTooltip
                        id="more-variable"
                        text="Increasing variability can make speech more expressive
      without output varying between re-generations. It can also lead to instabilities."
                      />
                    </span>
                  </Form.Label>
                </div>
              </div>
              {/* <div className="mt-1">
                <span>Clarity + Similarity Enhancement:</span>
                <Form.Range
                  className="mt-1"
                  min={0}
                  max={1}
                  step={0.1}
                  defaultValue={segmentData.AudioSettings.similarity_boost}
                  onChange={(e) => setSimilarity(e.target.value)}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    fontSize: '12px',
                    marginTop: '-10px',
                  }}
                >
                  <Form.Label style={{ fontWeight: 500, color: 'gray' }}>
                    <span>Low</span>
                    <span style={{ fontSize: '16px' }}>
                      <InformationCircleTooltip
                        id="low"
                        text="Low values are recommended if background artifacts are present in generated speech."
                      />
                    </span>
                  </Form.Label>
                  <Form.Label style={{ fontWeight: 500, color: 'gray' }}>
                    <span>High</span>
                    <span style={{ fontSize: '16px' }}>
                      <InformationCircleTooltip
                        id="high"
                        text="High enhancement boosts overall voice clarity and target speaker similarity.
         Very high values can cause artifacts, so adjusting this setting to find the optimal value is encouraged."
                      />
                    </span>
                  </Form.Label>
                </div>
              </div> */}
              <div className="mt-1">
                <span>Speaker Style:</span>
                <Form.Range
                  className="mt-1"
                  min={0}
                  max={1}
                  step={0.05}
                  defaultValue={segmentData.AudioSettings.style}
                  onChange={(e) => setStyle(e.target.value)}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    fontSize: '12px',
                  }}
                >
                  <Form.Label
                    style={{
                      fontWeight: 500,
                      color: 'gray',
                      marginTop: '-6px',
                    }}
                  >
                    Normal (Fastest)
                  </Form.Label>
                  <Form.Label
                    style={{
                      fontWeight: 500,
                      color: 'gray',
                      marginTop: '-10px',
                    }}
                  >
                    <span>Exaggerated</span>
                    <span style={{ fontSize: '16px' }}>
                      <InformationCircleTooltip
                        id="exaggerated"
                        text="High values are recommended if the style of the speech should be exaggerated compared to the uploaded audio.
           Higher values can lead to more instability in the generated speech. Setting to 0.0 will greatly increase generation speed and is the default setting."
                      />
                    </span>
                  </Form.Label>
                </div>
              </div>
              {/* <div
                className="mt-2"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Form.Check
                  type="checkbox"
                  label="Speaker Boost"
                  checked={isChecked}
                  onChange={(e) => handleCheckboxChange(e)}
                />
                <span
                  className="mb-1"
                  style={{ fontSize: '16px', color: 'gray' }}
                >
                  <InformationCircleTooltip
                    id="speaker-boost"
                    text="Boost the similarity of the synthesized speech and the voice at the cost of some generation speed."
                  />
                </span>
              </div> */}
              <div
                className="mt-2"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Form.Label className="mt-2">Speed:</Form.Label>
                <InputGroup
                  className="ms-2"
                  size="sm"
                  style={{ width: '200px' }}
                >
                  <Button
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    variant="secondary"
                    onClick={(e) => minSpeedChange()}
                  >
                    <FaMinus />
                  </Button>
                  <Form.Control
                    type="text"
                    value={speed}
                    onChange={(e) => {
                      if (
                        e.target.value >= minSpeed &&
                        e.target.value <= maxSpeed
                      ) {
                        setSpeed(e.target.value);
                      }
                    }}
                    style={{ textAlign: 'center' }}
                  />
                  <Button
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    variant="secondary"
                    onClick={(e) => maxSpeedChange()}
                  >
                    <FaPlus />
                  </Button>
                </InputGroup>
              </div>
            </div>
          </div>
        </div>

        <div
          className="mt-3"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          {/* generateSingleAudioSegment button    here---------------------------------------------------------------->   */}

          {wait === false ? (
            <Button
              variant="success"
              onClick={(e) => generateSingleAudioSegment()}
            >
              Generate
            </Button>
          ) : (
            <div>
              <Button variant="secondary" disabled>
                Generate
              </Button>
              <span>
                <img
                  style={{ width: '50px', height: '50px', marginLeft: '10px' }}
                  src={loading}
                  alt="loading"
                />
              </span>
            </div>
          )}
        </div>
      </Form>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div className="fileToolBar mt-3">
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <audio
              id="audio-Player"
              type="audio/wav"
              preload="none"
              key={`audio-${count}`}
              className="ms-2"
              src={
                generatedData.length > 0
                  ? `${generatedData[
                      count - 1
                    ].AudioSegmentUrl.trim()}?random=${Math.random()}`
                  : `${segmentData.AudioSegmentUrl.trim()}?random=${Math.random()}`
              }
              controls
              disablePictureInPicture
              controlsList="nodownload noplaybackrate"
            />
            <span
              id="current-time"
              style={{
                fontWeight: 600,
                fontSize: '14px',
                width: '53px',
                display: 'inline-block',
              }}
            ></span>{' '}
            <span
              className="ms-1 me-1"
              style={{ fontWeight: 600, fontSize: '14px' }}
            >
              /
            </span>{' '}
            <span
              id="total-time"
              style={{ fontWeight: 600, fontSize: '14px' }}
            ></span>
          </div>
          <Button variant="primary" onClick={(e) => updateAudioSegment()}>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}
